<template>
    <div class="raiseBodyBg">
        <div>
          <img src="images/integration5.jpg" alt="" class="raseBodyBgImg">
        </div>
        <div class="raiseBodyBgPadding">
          <div class="raiseBodyCrumbs">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item ><span @click="raiseStepOne()" class="generalIntroduce" >总体介绍</span></el-breadcrumb-item>
              <el-breadcrumb-item v-if="raiseStep2||raiseStep3"><span @click="raiseStepTwo()">相关任务</span></el-breadcrumb-item>
              <el-breadcrumb-item v-if="raiseStep3"><span @click="raiseStepTree()">{{taskTitle}}</span></el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-if="raiseStep1">
            <div class="raiseBodyTabImg">
              <p @click="getdrillType(drillListArr1.id,1)" :class="raiseTabShow==1?'raiseTabActive':''">{{drillListArr1.name}}</p>
              <p  @click="getdrillType(drillListArr2.id,2)" :class="raiseTabShow==2?'raiseTabActive':''">{{drillListArr2.name}}</p>
<!--              <img src="images/actual1.svg"/>-->
<!--              <img src="images/actual2.svg"/>-->
            </div>
            <div class="startTask">
              <el-button type="success" plain @click="startTask()">开始任务</el-button>
            </div>
            <div class="drillDetailFlex">
              <div v-html="drillDetail"></div>
            </div>

          </div>
          <div v-if="raiseStep2">
            <div class="raiseStep2Flex" v-if="userInfo&&!userInfo.teacherType">
              <p v-for="(item,index) in raiseStepTab" :key="index" :class="raiseStepShow==index?'raiseStepActive':'raiseStep2Flexp'" @click="changeRaise(index)">{{item.name}}</p>
            </div>
            <div>
<!--              任务列表-->
              <div class="taskFlex" v-for="(item,index) in taskList" :key="index">
                <div class="taskTitleFlex" :style="changeBackgRound(item)">
<!--                  <img :src="item.logo" class="taskImgs"/>-->
                  <span >{{item.name}}</span>
                </div>
                <div class="taskTitleChildrenFlex">
                  <p class="taskListTitleFlex" v-for="(item1,index1) in item.children" :key="index1" @click="changeTaskCatelogList(item1)">
<!--                    <img :src="item1.logo"/>-->
                    <span >{{item1.title}}</span>
                  </p>
                </div>
                <el-button @click="taskMore(item)" style="height: 38px">更多</el-button>
              </div>
<!--              分页-->
              <div style="margin: 30px 0">
                <el-pagination
                    class="text_center"
                    background
                    @current-change="handleTaskChange"
                    :current-page.sync="taskPage"
                    :page-size="taskSize"
                    layout="total,  prev, pager, next, jumper"
                    :total="taskTotal"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div v-if="raiseStep2Children">
            <div class="raiseStep2Flex">
              <p v-for="(item,index) in raiseStepTab" :key="index" :class="raiseStepShow2==index?'raiseStepActive':'raiseStep2Flexp'" @click="changeRaiseTab(index)">{{item.name}}</p>
            </div>
            <div>
              <!--              任务列表-->
              <div class="taskFlex" >
                <div class="taskTitleFlex" :style="changeBackgRound(taskListDetail)">
<!--                  <img :src="taskListDetail.logo" class="taskImgs"/>-->
                  <span >{{taskListDetail.name}}</span>
                </div>
                <div class="taskTitleChildrenFlex">
                  <p class="taskListTitleFlex" v-for="(item1,index1) in taskListDetail.children" :key="index1" @click="changeTaskCatelogList(item1)">
                    <!--                    <img :src="item1.logo"/>-->
                    <span>{{item1.title}}</span>
                  </p>
                </div>
              </div>
              <!--              分页-->
              <div style="margin: 30px 0">
                <el-pagination
                    class="text_center"
                    background
                    @current-change="handleTaskListChange"
                    :current-page.sync="nextPage"
                    :page-size="nextSize"
                    layout="total,  prev, pager, next, jumper"
                    :total="nextTotal"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div v-if="raiseStep3" class="raiseStepBg">
            <div v-if="procedureCatalogShow">
              <div>
                <h3 class="taskTitls">{{taskTitle}}</h3>
              </div>
              <div class="raiseStepMenuFont">
                <el-menu
                    v-if="showMenu"
                    :default-active="taskActiveId"
                    class="el-menu-vertical-demo"
                    @select="selsect"
                >
                  <el-submenu
                      v-for="(item, index) in taskCatalogList"
                      :key="index"
                      :index="item.id?item.id:''">
                    <template slot="title">
                      <span class="catalog" @click="taskSubmit(item)">{{ item.name }}</span>
                    </template>
                    <el-menu-item-group
                        v-for="(itemChildren, indexChildren) in item.children"
                        :key="indexChildren"
                    >
                      <el-menu-item :index="itemChildren.id+''">
                        <div slot="title" @click="chooseChilrden(itemChildren)">
                          <span class="zhangjieWidth">{{ itemChildren.name }}</span>
                          <img v-if="!userInfo.teacherType&&itemChildren.requiredReading==1&&itemChildren.areRead==0" src="images/lock.png" alt="" class="lockTask"/>
                        </div>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
              </div>

            </div>
            <div class="raiseBgwidthOVer">
              <div v-if="catalogDetailShow">
                <div v-html="catalogDetail"></div>
                <div v-if="catalogDetail" class="procedure">
                  <el-button type="primary" plain @click="prevStepTask()">上一步骤</el-button>
                  <el-button type="primary" @click="nextStepTask()">下一步骤</el-button>
                </div>
              </div>
              <div >
                <div v-if="teacherShow">
                  <div class="raiseBodyselectClass">
                    <el-cascader
                        v-model="classCascader"
                        :options="classGradeCas"
                        :props="props"
                    >
                      <template slot-scope="{ node, data }">
                        <span>{{ data.classGrade }}</span>
                      </template>
                    </el-cascader>
                    <el-button type="primary" plain class="resetTask" @click="resetSearch">重置</el-button>
                    <el-button type="primary" @click="searchStudent">搜索</el-button>
                  </div>
                  <el-divider></el-divider>
                  <!--              学生任务列表-->
                  <div>
                    <div class="stdTaskFlex" v-for="(item,index) in studentAdministrationData" :key="index" v-if="studentAdministrationData&&studentAdministrationData.length">
                      <el-tag effect="dark" >
                        <span class="raiseBodyUserTitle">{{item.name?item.name:item.telNumber}}</span>
                      </el-tag>
                      <p>{{item.name?item.name:item.telNumber}}提交的任务</p>
                      <p>{{item.className}}</p>
                      <p @click="selectTaskDetail(item,index)">查看详情</p>
                    </div>
                  </div>
                  <div style="margin: 30px 0">
                    <el-pagination
                        class="text_center"
                        background
                        @current-change="handlestdChange"
                        :current-page.sync="stdTaskPage"
                        :page-size="stdTaskSize"
                        layout="total,  prev, pager, next, jumper"
                        :total="stdTaskTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div v-if="userDetailShow" class="userDetailPad">
                  <div>
                    <div class="userTaskDetailFlex">
                      <el-tag effect="dark" >
                        <span v-if="StduserInfo&&Object.keys(StduserInfo).length">{{StduserInfo.name?StduserInfo.name:StduserInfo.telNumber}}</span>
                      </el-tag>
                      <div>
                        <p v-if="StduserInfo&&Object.keys(StduserInfo).length">{{StduserInfo.name?StduserInfo.name:StduserInfo.telNumber}}提交的任务</p>
                        <!--                      <p>今天18:03</p>-->
                      </div>
                    </div>
                    <div class="userTaskDetailFlex">
                      <!--                    <span class="previousTask" @click="prevTaskDetail()">上一个</span>-->
                      <span class="nextTask" @click="nextTaskDetail()">下一个</span>
                    </div>
                  </div>
                  <div class="raiseBodyTaskTitle">
                    <p>今日任务提交</p>
                    <p v-if="taskDetailObj&&taskDetailObj.textContent">{{taskDetailObj.textContent}}</p>
                  </div>
                  <div class="raiseBodyTaskTitle">
                    <p>任务附件</p>
                    <div v-if="taskDetailObj&&Object.keys(taskDetailObj.fileInfos).length" v-for="(item,index) in taskDetailObj.fileInfos" :key="index" class="uploadPreviewFlex">
                      <p>{{item.fileOriginalName}}</p>
                      <el-button type="primary" @click="adjunctPreview(item)">预览</el-button>
                    </div>
                  </div>
                  <div class="raiseBodyReply">
                    <div class="raiseBodyFontColor" >
                      <div v-for="(item,index) in ResultEvaluateList" :key="index" v-if="ResultEvaluateList&&ResultEvaluateList.length">
                        <img src="images/tacitlyAvatar.png"/>
                        <div>
                          <p>{{item.reviewerName}}</p>
                          <!--                      <p @click="changeReplay">回复</p>-->
                        </div>
                        <p  class="commentTaskuser">评价</p>
                        <p>{{item.textContent}}</p>
                      </div>
                    </div>
                    <div >
                      <div v-for="(item,index) in studentResultReplyList" :key="index" class="replayStdFlex" v-if="studentResultReplyList&&studentResultReplyList.length">
                        <img src="images/tacitlyAvatar.png"/>
                        <p>{{item.replyName}}</p>
                        <p>回复</p>
                        <p v-if="item.reviewerName">{{item.reviewerName}}</p>
                        <p>{{item.textContent}}</p>
                      </div>
                    </div>
                  </div>
                  <div style="margin: 30px 0" v-if="ResultEvaluateList&&ResultEvaluateList.length">
                    <el-pagination
                        class="text_center"
                        background
                        @current-change="handleTaskCommentChange"
                        :current-page.sync="taskCommentPage"
                        :page-size="taskCommentSize"
                        layout="total,  prev, pager, next, jumper"
                        :total="taskCommentTotal"
                    >
                    </el-pagination>
                  </div>
                  <div class="raiseBodyTaskMr">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        v-model="taskTextarea">
                    </el-input>
                    <div>
                      <el-button type="primary" @click="teacjerSubComment()">发表评价</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="userShow">
              <h1 class="achievement">成果提交</h1>
              <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="taskDetailObj.textContent">
              </el-input>
              <div class="uploadList">
                <div v-for="(item,index) in taskDetailObj.fileInfos" :key="index" class="uploadPreviewFlex" v-if="taskDetailObj&&Object.keys(taskDetailObj.fileInfos).length">
                  <p>{{item.fileOriginalName}}</p>
                  <el-button type="primary" @click="adjunctPreview(item)">预览</el-button>
                  <el-button type="primary" plain @click="deleteTaskAdjunct(item)">删除</el-button>
                </div>
              </div>
              <div class="uploadFlex">
                <el-upload
                    class="upload-demo"
                    :http-request="uploadFile"
                    action="/frontApi/baseFile/upload"
                    >
                  <el-button icon="el-icon-upload2" type="primary">点击上传</el-button>
                </el-upload>
                <span class="uploadFontsize">上传文件（图片、音频等大小请控制在30M以内）</span>
              </div>
              <div v-if="progress" class="progressMr">
                <el-progress :percentage="percentage" ></el-progress>
              </div>
              <div class="submitTaskCenter">
                <el-button type="primary" @click="submitTask()" :disabled="disabled">提交任务</el-button>
              </div>
<!--              用户-->
              <div class="raiseBodyReply">
                <div class="raiseBodyFontColor" >
                  <div v-for="(item,index) in ResultEvaluateList" :key="index" v-if="ResultEvaluateList&&ResultEvaluateList.length">
                    <img src="images/tacitlyAvatar.png"/>
                    <div>
                      <p>{{item.reviewerName}}</p>
                      <p style="cursor: pointer" @click="changeReplay">回复</p>
                    </div>
                    <p class="commentTaskuser">评价</p>
                    <p>{{item.textContent}}</p>
                  </div>
                </div>
                <div >
                  <div v-for="(item,index) in studentResultReplyList" :key="index" class="replayStdFlex" v-if="studentResultReplyList&&studentResultReplyList.length">
                    <img src="images/tacitlyAvatar.png"/>
                    <p>{{item.replyName}}</p>
                    <p>回复</p>
                    <p v-if="item.reviewerName">{{item.reviewerName}}</p>
                    <p>{{item.textContent}}</p>
                  </div>
                </div>
                <div class="submitTaskComment" v-if="replyTaskShow">
                  <el-input v-model="stdReplayInput"></el-input>
                  <el-button type="primary" plain class="submitCommentTask" @click="stdPublishComment()">发表评论</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- 图片 -->
      <el-dialog :visible.sync="designWhindow" :show-close="true">
        <div class="taskWhindowImgs">
          <img :src="resourceImg" alt=""/>
        </div>
      </el-dialog>
      <!--    ppt-->
      <div class="pptShowWhindow">
        <el-dialog :visible.sync="pptWhindow" :show-close="true" >
          <div class="coursePPtImgFlex">
            <div>
              <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
                <img :src="item?item:''" alt="" @click="changePPtImg(item)"/>
              </div>
            </div>
            <div>
              <el-image :src="coursePPtimg" :preview-src-list="pptResourceImg"  class="coursePPtBigIMg"></el-image>
            </div>
          </div>
        </el-dialog>
        <div style="margin-top: 120px">
          <Footer></Footer>
        </div>
      </div>
      <!--    视频播放-->
      <el-dialog
          :visible.sync="videoWhindow"
          :show-close="true"
          :append-to-body="true"
          :destroy-on-close="true">
        <div class="resourceImgas" v-if="videoWhindow">
          <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />
        </div>
      </el-dialog>
    </div>
</template>
<script>
import {
  drillList,
  drillType,
  drillModuleList,
  drillTaskList,
  drillTaskCatalogList,
  drillTaskCatalogDetail,
  userSubmitTask,
  selectStudentList,
  userTaskDetail,
  addStdResourceAliYun,
  studentResultEvaluateReplyList,
  studentResultEvaluateList,
  teacherUpLoad,
  saveStudentResultEvaluate,
  audioImg,
  saveStudentResultEvaluateReply,
  classNameList, gradeNameList,saveStudentCatalogProgress
} from "@/api";
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {filePlayType} from "@/utils/fileUtil";
import {createUploader} from "@/utils/aliyunVideoUpload";
import Footer from './footer.vue'
export default {
  components:{VueAliplayerV2,Footer},
    data(){
        return{
          showMenu: true,
          studentResultReplyList:[],
          replayData:{},
          ResultEvaluateList:[],
          designWhindow:false,
          resourceImg:[],
          pptWhindow:false,
          pptResourceImg:[],
          coursePPtimg:'',
          videoWhindow:false,
          videoOptions: {},
          raiseStepTab:[
            {id:1,name:"全部"},
            {id:2,name:"已完成"},
            {id:3,name:"未完成"},
          ],
          taskList:[],
          raiseStep1:true,
          raiseStep2:false,
          userShow:false,
          teacherShow:false,
          userDetailShow:false,
          raiseStep2Children:false,
          raiseStepShow:0,
          raiseStepShow2:0,
          raiseStep3:false,
          stdReplayInput:'',
          drillListArr1:{},
          drillListArr2:{},
          drillDetail:'',
          drillId:'',
          classCascader:[],
          classGradeCas:[],
          props: {
            value: 'classGrade',
            label: 'classGrade',
            lazy: true,
            lazyLoad: this.handleChangeGreade
          },
          classValue: '',
          taskTextarea:'',
          taskPage:1,
          taskSize:10,
          taskTotal:0,
          userInfo:{},
          taskListDetail:{},
          nextPage:1,
          nextSize:10,
          nextTotal:0,
          stdTaskPage:1,
          stdTaskSize:10,
          stdTaskTotal:0,
          taskListDetailId:'',
          taskCatalogList:[],
          catalogDetail:'',
          taskTitle:'',
          catalogDetailShow:false,
          taskCatalogId:'',
          taskIds:{},
          studentAdministrationData:[],
          StduserInfo:{},
          uploadId:[],
          disabled:false,
          taskDetailObj:{
            textContent:'',
            fileInfos:[],
            aliVideoOssUrl:'',
            thirdPartyStorageId:''
          },
          replyTaskShow:false,
          progress:false,
          percentage:0,
          taskCommentPage:1,
          taskCommentSize:10,
          taskCommentTotal:0,
          stdIndex:"",
          studentUserId:'',
          taskChildren:[],
          taskIndex:0,
          procedureCatalogShow:false,
          taskChildrenIdArr:[],
          taskActiveId:'',
          raiseTabShow:1,
          catelogListSHow:false
        }
    },
  methods:{
      //开始任务
      startTask(){
        this.raiseStep1=false;
        this.raiseStep2=true;
        this.raiseStep2Children=false
        let data={
          trainingTaskTypeId:this.drillId?this.drillId:this.drillListArr1.id,
          page:this.taskPage,
          size:this.taskSize
        }
        drillModuleList(data).then(res=>{
          this.taskList=res.data.records;
          this.taskTotal=parseInt(res.data.total)
          this.getTaskList()
        })
      },
      //更多任务
      taskMore(item){
        this.taskListDetail=item;
        this.taskListDetailId=item.id
        this.raiseStep2=false;
        this.raiseStep2Children=true
        // this.nextTotal=parseInt(item.total);
        this.getModuleTaskList()
      },
      //获取模块下的任务列表
      getTaskList(){
        this.taskList.forEach((item,index)=>{
          let query={
            trainingTaskModuleId:item.id,
            page:1,
            size:6,
            queryState:''
          };
          if(this.raiseStepShow&&this.raiseStepShow==1){
            query['queryState']=1
          }else if(this.raiseStepShow&&this.raiseStepShow==2){
            query['queryState']=2
          }
          if(this.userInfo&&!this.userInfo.teacherType){
            query['loginUserId']=this.userInfo.id
          }
          drillTaskList(query).then(res=>{
            this.$set(this.taskList[index],  "children",res.data.records);
            this.$set(this.taskList[index],  "total",res.data.total);
          })
        });
      },
      //任务列表分页
      handleTaskListChange(nextPage){
        this.nextPage=nextPage;
        this.getModuleTaskList()
      },
      //任务模块分页
      handleTaskChange(taskPage){
        this.taskPage=taskPage;
        this.startTask()
      },
      //教师查看学生列表分页
      handlestdChange(stdTaskPage){
        this.stdTaskPage=stdTaskPage;
        this.getStudentList()
      },
      //tab
      changeRaise(index){
        this.raiseStepShow=index;
        this.getTaskList();
      },
      //具体模块的任务列表
      getModuleTaskList(){
        let query={
          trainingTaskModuleId:this.taskListDetailId,
          page:this.nextPage,
          size:this.nextSize,
          queryState:''
        };
        if(this.raiseStepShow2&&this.raiseStepShow2==1){
          query['queryState']=1
        }else if(this.raiseStepShow2&&this.raiseStepShow2==2){
          query['queryState']=2
        }
        if(this.userInfo&&!this.userInfo.teacherType){
          query['loginUserId']=this.userInfo.id
        }
        drillTaskList(query).then(res=>{
          this.taskListDetail.children=res.data.records;
          this.nextTotal=parseInt(res.data.total);
        })
      },
    //  列表下的tab
      changeRaiseTab(index){
        this.raiseStepShow2=index;
        this.nextPage=1
        this.getModuleTaskList()
      },
    //  更换背景色
      changeBackgRound(item){
        let R = Math.floor(Math.random() * 130+110);
        let G = Math.floor(Math.random() * 130+110);
        let B = Math.floor(Math.random() * 130+110);
        return {
          background: 'rgb(' + R + ',' + G + ',' + B + ')'
        };
      },
    //任务类型列表
      getdrillList(){
        drillList().then(res=>{
          if(res.data.records){
            this.drillListArr1=res.data.records[0]
            this.drillListArr2=res.data.records[1];
            this.getdrillType(res.data.records[0].id,1)
          }

        })
      },
    //  任务类型下的内容
      getdrillType(id,index){
        this.raiseTabShow=index
        this.drillId=id
        let data={
          trainingTaskTypeId:id
        };
        drillType(data).then(res=>{
          this.drillDetail=res.data
        })
      },
    //  任务下的目录
      changeTaskCatelogList(item){

        if(this.userInfo&&this.userInfo.teacherType){
          this.taskIndex=0;
          this.taskIds=item
          this.taskTitle=item.title;
          this.raiseStep3=true
          this.procedureCatalogShow=true;
          this.raiseStep2=false;
          this.raiseStep2Children=false;
          this.teacherShow=false;
          this.userDetailShow=false;
          this.catelogListSHow=true
          this.getCatalogList()
        }else{
          if(this.userInfo.classId){
            this.taskIndex=0;
            this.taskIds=item
            this.taskTitle=item.title;
            this.raiseStep3=true
            this.procedureCatalogShow=true;
            this.raiseStep2=false;
            this.raiseStep2Children=false;
            this.teacherShow=false;
            this.userDetailShow=false;
            this.catelogListSHow=true
            this.getCatalogList()
          }else{
            this.$alert('暂无权限，请加入班级后查看。', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                // this.$message({
                //   type: 'info',
                // });
              }
            });
          }
        }
      },
      getTaskCatelogList(){
        let data={
          taskContentId:this.taskIds.id
        };
        drillTaskCatalogList(data).then(res=>{
          if(res.data&&res.data.length){
            this.catalogDetailShow=true;
            let courseItemList = res.data;
            courseItemList.push({name:'成果提交',pid:'0'});
            let childrenId=courseItemList[0].children[0].id;
            if(this.catelogListSHow){
              this.getTaskDetailContent(childrenId);
              this.taskActiveId=childrenId+"";
            }
            courseItemList.forEach(item=>{
              if(item.children){
                item.children.forEach(item1=>{
                  if(item1.id==childrenId){
                    item1.areRead=1;
                    item1.requiredReading=0;
                  }
                })
              }
            })
            this.taskCatalogList=courseItemList;
            let arr=[];
            let arrId=[];
            courseItemList.forEach(item=>{
              if(item.children){
                item.children.forEach((item1,index1)=>{
                  arr.push(item1);
                  arrId.push(item1.id)
                })
              }
            });
            this.taskChildren=arr;
            this.taskChildrenIdArr=arrId;
          }
        })
      },
      //获取目录列表
      getCatalogList(){
        this.showMenu = false
        let data={
          taskContentId:this.taskIds.id
        };
        drillTaskCatalogList(data).then(res=>{
          if(res.data&&res.data.length){
            this.catalogDetailShow=true;
            let courseItemList = res.data;
            courseItemList.push({name:'成果提交',pid:'0'});
            let childrenId=courseItemList[0].children[0].id;
            if(this.catelogListSHow){
              this.getTaskDetailContent(childrenId);
              // this.taskActiveId=childrenId+"";
            }
            courseItemList.forEach(item=>{
              if(item.children){
                item.children.forEach(item1=>{
                  if(item1.id==childrenId){
                    item1.areRead=1;
                    item1.requiredReading=0;
                  }
                })
              }
            })
            this.taskCatalogList=courseItemList;
            let arr=[];
            let arrId=[];
            courseItemList.forEach(item=>{
              if(item.children){
                item.children.forEach((item1,index1)=>{
                  arr.push(item1);
                  arrId.push(item1.id)
                })
              }
            });
            this.taskChildren=arr;
            this.taskChildrenIdArr=arrId;
            this.taskActiveId=childrenId
            this.$nextTick(()=>{
              this.showMenu = true
              this.taskActiveId = String(childrenId);
            })
          }
          this.catelogListSHow=false
        })
      },
    //  选择目录
      chooseChilrden(item){
        Array.prototype.getArrayIndex=function(obj){
          for(var i=0;i<this.length;i++){
            if(this[i]===obj){
              return i;
            }
          }
          return -1;
        }
        this.taskIndex=this.taskChildrenIdArr.getArrayIndex(item.id)
        if(item.areRead!==0||this.userInfo.teacherType){
          this.taskCatalogId=item.id
          this.userShow=false;
          this.catalogDetailShow=true;
          this.teacherShow=false;
          this.userDetailShow=false
          this.getTaskDetailContent(item.id)
        }else if(item.requiredReading==0&&item.areRead==0) {
          this.taskCatalogId=item.id
          this.userShow=false;
          this.catalogDetailShow=true;
          this.teacherShow=false;
          this.userDetailShow=false
          this.getTaskDetailContent(item.id)
        }else{
          this.$message('请解锁上一步骤')
        }
      },
    //  任务目录详情
      getTaskDetailContent(id){
        let data={
          trainingTaskContentCatalogId:id
        };
        drillTaskCatalogDetail(data).then(res=>{
          this.catalogDetail=res.data
        })
      },
    //  实战训练任务指定学生结果
      getStdTaskDetails(){
        let data={
          trainingTaskContentId:this.taskIds.id,
          studentId:this.userInfo.id
        };
        userTaskDetail(data).then(res=>{
          if(res.data&&res.data!=null){
            this.taskDetailObj=res.data;
            this.getStudentResultEvaluateList(res.data.id);
          }else{
            this.taskDetailObj={};
            this.ResultEvaluateList=[];
            this.studentResultReplyList=[]
          }
        })
      },
    //  显示成果提交
      taskSubmit(item){
        this.taskCatalogList.forEach(taskItem=>{
          if(taskItem.children){
            taskItem.children.forEach(item1=>{
              if(item1.areRead==1&&item.name=='成果提交'&&this.userInfo&&!this.userInfo.teacherType){
                this.catalogDetailShow=false
                this.userShow=true;
                this.getStdTaskDetails()
              }else if(item1.areRead==0){
                this.catalogDetailShow=false
                this.userShow=false;
              }
            })
          }
        })
        if(item.name=='成果提交'&&this.userInfo&&this.userInfo.teacherType){
          this.stdTaskPage=1
          this.teacherShow=true;
          this.catalogDetailShow=false;
          this.userShow=false;
          this.userDetailShow=false
          this.getStudentList();
          this.getGradeList();
        }
      },
    //  上一步骤
      prevStepTask(){
        let index=this.taskIndex--;
        let taskCatalog=this.taskChildren[index-1];
        let upTaskId=this.taskChildrenIdArr[index-1]
        this.taskActiveId=upTaskId
        // let presentTaskCatalogId=this.taskChildren[index].id;
        if(this.taskChildren[index-1]!==undefined){
          if(taskCatalog.name!=='成果提交'){
            this.getTaskDetailContent(taskCatalog.id)
          }
        }
        window.scrollTo({
          top: 580,
          behavior: "smooth",
        });
      },

    //  下一步骤
      nextStepTask(){
        this.catalogDetailShow=true
        let index=this.taskIndex++;
        let taskCatalog=this.taskChildren[index+1];
        let presentTaskCatalogId=this.taskChildrenIdArr[index];
        let nextTaskId=this.taskChildrenIdArr[index+1]
        this.taskActiveId=nextTaskId
        let data={
          trainingTaskContentCatalogId:presentTaskCatalogId,
          trainingTaskTypeId:this.taskIds.trainingTaskTypeId,
          trainingTaskModuleId:this.taskIds.trainingTaskModuleId,
          trainingTaskContentId:this.taskIds.id
        }
        if(taskCatalog!==undefined){
          this.getTaskDetailContent(taskCatalog.id)
          saveStudentCatalogProgress(data).then(res=>{
            if(res.code==0){
              this.getTaskCatelogList()
            }
          })
        }else if(this.userInfo&&!this.userInfo.teacherType){
          this.taskIndex=0
          this.catalogDetailShow=false
          this.userShow=true;
          saveStudentCatalogProgress(data).then(res=>{
            if(res.code==0&&taskCatalog!==undefined){
              this.getTaskCatelogList()
              this.getStdTaskDetails()
            }
          })
        }else if(this.userInfo&&this.userInfo.teacherType){
          this.stdTaskPage=1
          this.teacherShow=true;
          this.catalogDetailShow=false;
          this.userShow=false;
          this.userDetailShow=false
          this.getStudentList();
          this.getGradeList();
        }
        window.scrollTo({
          top: 580,
          behavior: "smooth",
        });
      },
    //用户提交任务
      submitTask(){
        if(!this.taskDetailObj.textContent){
          this.$message('提交内容不可为空');return
        }
        let data={
          trainingTaskTypeId:this.taskIds.trainingTaskTypeId,
          trainingTaskModuleId:this.taskIds.trainingTaskModuleId,
          trainingTaskContentId:this.taskIds.id,
          textContent:this.taskDetailObj.textContent
        };
        if(this.taskDetailObj){
          data['id']=this.taskDetailObj.id
        }
        if(this.taskDetailObj&&this.taskDetailObj.fileInfos){
          this.taskDetailObj.fileInfos.forEach(item=>{
            this.uploadId.push(item.id)
          })
          data['fileIds']=this.uploadId.join(',')
        }
        userSubmitTask(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.disabled=true;
            this.raiseStepTwo()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //  重置
      resetSearch(){
        this.classCascader='';
      },
      //  获取学生列表
      getStudentList(){
        let data={
          page:this.stdTaskPage,
          size:this.stdTaskSize
        }
        if (this.classCascader) {
          data['classGrade'] = this.classCascader[0];
          data['className'] = this.classCascader[1]
        }
        selectStudentList(data).then(res=>{
            this.studentAdministrationData=res.data.records;
            this.stdTaskTotal=JSON.parse(res.data.total);
            if(!res.data.records.length){
              this.$message('已经是最后了~~');return
            }
            if(this.stdIndex){
              this.getuserTaskDetial(this.studentAdministrationData[0].id);
              this.StduserInfo=this.studentAdministrationData[0]
            }

        })
      },
      //上传文件
      uploadFile(res){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if(!res.file)return
        let fileType = filePlayType(res.file.name);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          this.progress=true
          let aliYundata={
            "fullFileName": res.file.name,
            "coverUrl": "www.xn---xn--2d4a-439uw60e.com",
            "fileSize": res.file.size,
            "schoolId": userInfo.schoolId
          }
          let uploaderObj  = createUploader(aliYundata,"",(res,uploadInfo)=>{
            if(res === true){
              let data={
                thirdPartyStorageId:uploadInfo.uploadInfo.videoId,
                fileName:uploadInfo.uploadInfo.file.name,
                fileSize:uploadInfo.uploadInfo.file.size,
                bizType:"studentActualTasksResource"
              };
              addStdResourceAliYun(data).then(res=>{
                if(res.code===0){
                  this.$message({
                    message: "上传成功",
                    type: "success",
                    duration: 2000
                  });
                  this.taskDetailObj.fileInfos.push({id:res.data.id,fileOriginalName:res.data.fileName,thirdPartyStorageId:res.data.thirdPartyStorageId})
                }else{
                  this.$message.error(res.msg)
                }
              })
            }
          },(uploadInfo, totalSize, progress)=> {
            this.percentage = progress * 100
          })
          uploaderObj.addFile(res.file)
          uploaderObj.startUpload()
        }else{
          var forms = new FormData()
          forms.append('file',res.file)
          forms.append('bizType','studentActualTasksResource')
          forms.append('aliVideoOssData',1);
          forms.append('schoolId',userInfo.schoolId)
          teacherUpLoad(forms).then(res=>{
            if(res.code==0){
              this.$message({
                message: "上传成功",
                type: "success",
                duration: 2000
              });
              this.taskDetailObj.fileInfos.push({id:res.data.id,fileOriginalName:res.data.fileOriginalName,aliVideoOssUrl:res.data.aliVideoOssUrl});
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      },
    //  显示回复输入框
      changeReplay(item){
        this.replayData=item
        this.replyTaskShow=true
      },
      //查询用户任务详情接口
      getUserTaskDetail(){
        let data={
          trainingTaskContentId:this.taskIds.id,
          studentId:this.StduserInfo.id
        };
        userTaskDetail(data).then(res=>{
          this.taskDetailObj=res.data;
          this.getStudentResultEvaluateList(res.data.id)
        })
      },
    //  教师查看学生任务详情
      selectTaskDetail(item,index){
        this.stdIndex=index
        this.StduserInfo=item
        this.teacherShow=false
        let data={
          trainingTaskContentId:this.taskIds.id,
          studentId:item.id
        };
        userTaskDetail(data).then(res=>{
          if(res.data&&res.data!=null){
            this.taskDetailObj=res.data;
            this.getStudentResultEvaluateList(res.data.id)
          }else{
            this.taskDetailObj={};
            this.ResultEvaluateList=[];
            this.studentResultReplyList=[]
          }
        });
        this.userDetailShow=true;
      },
    //  查看教师对学生任务评论列表
      getStudentResultEvaluateList(id){
        let data1={
          trainingTaskStudentResultId:this.taskDetailObj.id,
          page:this.taskCommentPage,
          size:this.taskCommentSize,
        };
        studentResultEvaluateList(data1).then(res=>{
          this.ResultEvaluateList=res.data.records;
          this.taskCommentTotal=parseInt(res.data.total)
          res.data.records.forEach(item=>{
            this.getStdResultEvaluateReplyList(item.id)
          })
        })
      },
    //  查看教师评论分页
      handleTaskCommentChange(taskCommentPage){
        this.taskCommentPage=taskCommentPage;
        this.getStudentResultEvaluateList()
      },
    //  删除任务附件
      deleteTaskAdjunct(item){
        var index = this.taskDetailObj.fileInfos.indexOf(item)
        if (index !== -1) {
          this.taskDetailObj.fileInfos.splice(index, 1)
        }
      },
      //学生预览附件
      adjunctPreview(item){
        let fileType = filePlayType(item.fileOriginalName);
        if(fileType === 'imageTypeAry'||fileType === 'pdfTypeAry'){
          this.resourceImg = item.aliVideoOssUrl;
          this.designWhindow = true;
        };
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then((res)=>{
            res.data.forEach(item1=>{
              this.videoOptions.playauth =item1.playAuth
              this.videoOptions.vid =item.thirdPartyStorageId
            })
            this.videoWhindow = true;
          })
        }
      },
      //教师对学生发表评价
      teacjerSubComment(){
        if(!this.taskTextarea){
          this.$message('评论内容不能为空');return
        }
        let data={
          trainingTaskStudentResultId:this.taskDetailObj.id,
          textContent:this.taskTextarea,
        };
        saveStudentResultEvaluate(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: '评论成功',
              type: 'success'
            });
            this.taskTextarea='';
            this.getUserTaskDetail()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //查询学生回复教师列表
      getStdResultEvaluateReplyList(id){
        let data={
          trainingTaskStudentResultEvaluateId:id,
          page:1,
          size:20
        };
        studentResultEvaluateReplyList(data).then(res=>{
          if(res.data.records&&res.data.records.length){
            this.studentResultReplyList=res.data.records
          }
        })
      },
      //学生回复教师
      stdPublishComment(){
        if(!this.stdReplayInput){
          this.$message('提交内容不可为空');return
        }
        let data={
          trainingTaskStudentResultEvaluateId:this.replayData.id,
          reviewerType:this.replayData.reviewerType,
          reviewerId:this.replayData.reviewerId,
          textContent:this.stdReplayInput,
          reviewerName:this.replayData.reviewerName
        };
        saveStudentResultEvaluateReply(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: '评论成功',
              type: 'success'
            });
            this.stdReplayInput="";
            this.getStdTaskDetails()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //总体介绍显示
      raiseStepOne(){
        this.raiseStep1=true;
        this.raiseStep2=false;
        this.raiseStep3=false;
        this.raiseStep2Children=false
      },
      //相关任务
      raiseStepTwo(){
        this.raiseStep1=false;
        this.raiseStep2=true;
        this.raiseStep3=false;
        this.raiseStep2Children=false;
        this.userShow=false;
        this.taskActiveId = "";
      },
      //任务目录
      raiseStepTree(){
        this.raiseStep1=false;
        this.raiseStep2=false;
        this.raiseStep3=true;
      },
      //选择年级
      async handleChangeGreade(node, resolve) {
        const {level, value} = node;
        let data = {
          page: 1,
          size: 100,
          classGrade: value
        }
        if(data.classGrade&&data.classGrade!==''){
          let res = await classNameList(data)
          if (!res || !res.data || !res.data.records) return;
          const nodes = [];
          res.data.records.forEach((item) => {
            nodes.push({
              classGrade: item.name ? item.name : '暂无名称',
              leaf: level >= 1
            })
          })
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes);
        }
      },
      //获取年级名称列表
      getGradeList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          schoolId: userInfo.schoolId
        }
        gradeNameList(data).then(res => {
          this.classGradeCas = res.data.records;
        })
      },
      //搜索学生
      searchStudent(){
        this.getStudentList()
      },
      // 点击小图切换大图
      changePPtImg(item){
        this.coursePPtimg=item
      },
    //  获取用户任务详情
      getuserTaskDetial(id){
        let data={
          trainingTaskContentId:this.taskIds.id,
          studentId:id
        };
        userTaskDetail(data).then(res=>{
          if(res.data&&res.data!=null){
            this.taskDetailObj=res.data;
            this.getStudentResultEvaluateList(res.data.id)
          }else{
            this.taskDetailObj={};
            this.ResultEvaluateList=[];
            this.studentResultReplyList=[]
          }
        })
      },
    //  下一个学生任务
      nextTaskDetail(){
        if(this.studentAdministrationData[this.stdIndex+1]){
          let studentUserId=this.studentAdministrationData[this.stdIndex+1].id;
          if(this.stdIndex>=this.stdTaskSize-1){
            this.stdTaskPage++;
            this.getStudentList();
          }else if(studentUserId){
            this.getuserTaskDetial(studentUserId);
            this.stdIndex++;
            this.StduserInfo=this.studentAdministrationData[this.stdIndex];
          }
        }else{
          this.$message('已经是最后一个了')
        }

      },
    //  上一个任务
      prevTaskDetail(){
        if(this.stdIndex>=this.stdTaskSize){
          let studentUserId=this.studentAdministrationData[this.stdIndex-1].id;
          this.getuserTaskDetial(studentUserId)
          if(this.stdIndex>0){
            this.stdIndex--;
          }
          this.StduserInfo=this.studentAdministrationData[this.stdIndex];
        }else{
          this.stdTaskPage--;
          this.getStudentList();
        }
      },
      selsect(index,indexPath){
        // console.log('this.activeMenu = index',indexPath)
        // this.taskActiveId=indexPath;
      },
    //  底部链接
    goExternal(){
      window.open('https://beian.miit.gov.cn/')
    }
    },
    mounted(){
      document.documentElement.scrollTop = 0;
      this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
      this.$other.$emit('setIndexNav',2);
      this.getdrillList()
    }
}
</script>
<style scoped>
@import "../../public/css/raiseBody.css";
</style>
